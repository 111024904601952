<!--
  - Copyright (C) 2020. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <b-container
    class="py-4"
    fluid
  >
    <h1 class="mt-3" style="white-space: pre-wrap">{{ $t("ems.home.head") }}</h1>
    <div
      v-for="(data, i) in $t('ems.home.body')"
      :key="i"
    >
      <div
        v-if="data.type === 'text'"
        style="white-space: pre-wrap"
      >{{ data.content }}</div>
      <div
        v-else-if="data.type === 'image'"
        class="text-center map-wrapper mt-5"
      >
        <img thumb class="map" :src="baseURL + data.content" alt="map">
      </div>
    </div>
  </b-container>
</template>

<script>
  export default {
    name: 'Home',
    computed: {
      baseURL () {
        return process.env.BASE_URL
      }
    }
  }
</script>

<style lang="scss" scoped>
  .map-wrapper {
    //background: #f5f5f5;
    //padding: 0px;
  }
  .map{
    max-height: 70vh;
    max-width: 80vw;
  }

</style>
